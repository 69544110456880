import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import i18n from './i18n'
import { version } from '../package.json';
Vue.config.productionTip = false
import "@/assets/global.css"
import Maska from 'maska'
Vue.use(Maska)

import "@/assets/global.css"
import axios from 'axios'
Vue.prototype.$http = axios;

const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}


new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
export default version;