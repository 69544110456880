<template>
  <v-app  translate="no" class="notranslate">
    
    <!-- <v-navigation-drawer v-model="drawer" right fixed> -->
      <!-- <v-list-item>
        <v-list-item-avatar>
          <v-img :src="avatar"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            >{{ fname }} {{ lname }}

            <v-subheader>{{ points }}</v-subheader>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <!-- <v-divider></v-divider>
      <v-list dense> -->
        <!-- <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="
            $router.push({
              name: item.name,
              params: { routeparam: item.paramlink },
            })
          "
        >
          <v-list-item-icon>
            <v-img contain :src="item.icon"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <label>{{ item.title }}</label>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-list-item
          v-if=isLoggedIn
          @click=logout
        >
          <v-list-item-icon color="orange">
             <v-img contain src="img/m_ico8.png"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <label>Вихід</label>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if=isLoggedIn
          @click.native="$router.push({name:'Main'})"
        >
          <v-list-item-icon color="orange">
             <v-img contain src="img/m_ico8.png"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <label>Головна</label>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
     

    </v-navigation-drawer> -->

    <v-overlay :value="isLoading">
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="15"
        :value="cvalue"
        color="#ee6923"
      >
        {{ cvalue }}
      </v-progress-circular>
      <br />
    </v-overlay>
    <!-- <Snowf
  :amount="50"
  :size="5"
  :speed="1.5"
  :wind="1"
  :opacity="0.8"
  :swing="1"
  :image="null"
  :zIndex="null"
  :resize="true"
  color="#cccccc"
/> -->
  <!-- <div class="snowflakes" aria-hidden="true" style="z-index:0"> -->
    <!-- <div class="snowflake">
    ❅
    </div>
    <div class="snowflake">
    ❆
    </div>
    <div class="snowflake">
    ❅
    </div>
    <div class="snowflake">
    ❆
    </div>
    <div class="snowflake">
    ❅
    </div>
    <div class="snowflake">
    ❆
    </div>
    <div class="snowflake">
      ❅
    </div>
    <div class="snowflake">
      ❆
    </div>
    <div class="snowflake">
      ❅
    </div>
    <div class="snowflake">
      ❆
    </div>
    <div class="snowflake">
      ❅
    </div>
    <div class="snowflake">
      ❆
    </div> -->
    <!-- <div class="snowflake"></div>
    <div class="snowflake"></div>
    <div class="snowflake"></div>
    <div class="snowflake"></div>
    <div class="snowflake"></div>
    <div class="snowflake"></div>
    <div class="snowflake"></div>
    <div class="snowflake"></div>
  </div> -->
    <!-- <v-main style="z-index:500"> -->
    <v-main translate="no" class="notranslate">
      <div class="app_bg" style="position: fixed;z-index: 0;top:0;height:100vh; left: 0;right: 0;"></div>
      <router-view  @pushe="pushe" style="position: relative;"></router-view>
      


      <div id="header" style="     position: fixed;    top: 0;        min-width: 375px;    max-width: 500px;    width: 100%; background-color: white;        padding-bottom: 10px; padding-top: 10px;" v-if="!['Login'].includes(this.$route.name)">
      <v-row>
        <v-col  cols="3" style="display: flex; justify-content: start;      flex-direction: row;    align-items: center;    align-self: center;" class="pl-7">
        <!-- left icos -->
          <v-img src="img/profico.png" @click="$router.push({ name: 'Profile' })"  v-if="['Main'].includes(this.$route.name)"  style="max-width: 38px;"></v-img>
          <!-- <v-img v-if="!['Main'].includes(this.$route.name)" src="img/ico_exit.png"  @click="$router.push({ name: 'Main' })"  style="max-width: 38px;"></v-img> -->
          <v-img v-if="!['Main'].includes(this.$route.name)" src="img/ico_exit.png"  @click="go_back()"  style="max-width: 38px;"></v-img>
        
        </v-col>
        <v-col class="header_global"  @click="$router.push({ name: 'Main' })" cols="6" style="display: flex;    flex-direction: column;    align-items: center;    align-self: center;padding-bottom: 5px;">
          <!-- header_txt -->
          <div v-if="['Profile'].includes(this.$route.name)">Профіль</div>
          <div v-if="['Cert_arch'].includes(this.$route.name)">Архів сертифікатів</div>
          
          <div v-if="['News_arch'].includes(this.$route.name)">Сповіщення</div>
          <div v-if="['TPQ'].includes(this.$route.name)">Підтримка</div>
           <div v-if="['Main'].includes(this.$route.name)">iTeam</div>
           <div v-if="['News_pg'].includes(this.$route.name)">Планограми</div>
           <div v-if="['C_info'].includes(this.$route.name)">Про компанію</div>
           
           <div v-if="['Phototask'].includes(this.$route.name)" class="photo_header">Фото вітрини</div>
           
           <div v-if="['Phototask_dmx'].includes(this.$route.name)">Мій товарний запас</div>
           <div v-if="['Testtask'].includes(this.$route.name)">Завдання</div>
           <div v-if="['Balance'].includes(this.$route.name)">Мої бали</div>
           <div v-if="['News'].includes(this.$route.name)">Новини</div>
           <div v-if="['Withdraw_sel','Withdraw_fishka','Withdraw_silpo','Withdraw_wog','Withdraw_rozetka','Withdraw_novus','Withdraw_eva','Withdraw_epi','Withdraw_atb'].includes(this.$route.name)">Сертифікати</div>
          

           <div v-if="['Info_Brands_Davidoff','Info_Brands_Davidoff_SL','Info_Brands_Davidoff_reach','Info_Brands_Davidoff_PL','Info_Brands_Davidoff_40'].includes(this.$route.name)">iTeam</div>

          <div v-if="['Info_Brands_West','Info_Brands_West_XL','Info_Brands_West_UP','Info_Brands_West_KS'].includes(this.$route.name)">iTeam</div>

          <div v-if="['Info_Brands_Jade','Info_Brands_Jade_SSL'].includes(this.$route.name)">iTeam</div>

          
          <div v-if="['Info_Brands_ImperialClassic','Info_Brands_IC_Compact','Info_Brands_IC_25'].includes(this.$route.name)" style="font-size: 20px;">iTeam</div>

          <div v-if="['Info_Brands_Prima','Info_Brands_Prima_sribna','Info_Brands_Prima_lux'].includes(this.$route.name)">iTeam</div>

          <!-- Про важливе -->
          <div v-if="['Vazhl_sel','Vazhl_1','Vazhl_2','Vazhl_3','Vazhl_4','Vazhl_5','Vazhl_6','Vazhl_7',].includes(this.$route.name)">Про важливе</div>

        </v-col>
        <v-col  cols="3" style="    justify-content: end;display: flex;    flex-direction: row;    align-items: center;    align-self: center;" class="pr-7">
         <!-- right_icos -->
         <v-img src="img/mailico.png" @click="$router.push({ name: 'News_arch' })"  v-if="['Main'].includes(this.$route.name)" style="max-width: 38px;margin-right: 10px;" ></v-img>
          <v-img src="img/sapico.png" @click="$router.push({ name: 'TPQ' })"  v-if="!['TPQ'].includes(this.$route.name)" style="max-width: 38px;"></v-img>
        </v-col>

      </v-row>
    </div>


    <div id="footer" style="    position: fixed;    bottom: 0;        min-width: 375px;    max-width: 500px;    width: 100%;   background-color: white;    padding-bottom: 10px;" 
    v-if="!['Login'].includes(this.$route.name)">
      <v-row>
        <v-col  @click="$router.push({ name: 'Balance' })" cols="4" style="display: flex;    flex-direction: column;    align-items: center;    align-self: center;"><center><v-img src="img/footer_ico_balance.png" style="max-width: 55px;"></v-img></center></v-col>
        <v-col  @click="$router.push({ name: 'Main' })" cols="4" style="display: flex;    flex-direction: column;    align-items: center;    align-self: center;padding-bottom: 5px;"><center><v-img src="img/footer_home.png" style="max-width: 65px;"></v-img></center></v-col>
        <v-col  @click="$router.push({ name: 'Withdraw_sel' })" cols="4" style="display: flex;    flex-direction: column;    align-items: center;    align-self: center;"><center><v-img src="img/footer_ico_cert.png"  style="max-width: 55px;"></v-img></center></v-col>

      </v-row>
    </div>
    </v-main>


    <v-dialog
        z-index="55000"
        v-model="push_dialog"
        persistent
        width="330" style="z-index:55000 !important;"
      >
      <v-card class="simple_card" style="" elevation="10">
          <v-card-title  class="headline lighten-2" style="margin-bottom:-10px;justify-content:center;">{{push_header}}<br>{{push_sender}}  
            <div @click="push_dialog = false" class="close_dialog"></div>
          
          
          </v-card-title>
          <v-card-text>
             <v-row> <v-col v-html="push_text">
             
                </v-col>
             </v-row>
           
            
          </v-card-text>
          <!-- <v-card-actions>
            
            <v-btn
              class="link"
              text
              @click="push_dialog = false"
            >
              Закрити
            </v-btn>
          </v-card-actions> -->
      </v-card>
      <div class="popup_bottom_robot"></div>
    </v-dialog>
    

  </v-app>
  
</template>







<script>
import axios from "axios";
//import Snowf from 'vue-snowf';
import { API_LOCATION } from "../config";
export default {
  name: "App",

  components: {
    //Snowf
    },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    },
    
  },

  data: () => ({
    //iconTeam: require("./assets/LogoITG.svg"),
    drawer: false,

    push_dialog:false,
    push_header:'',
    push_text:'',
    push_sender:'',
    sender_types:['від iTeam','від власника мережі','від Торгового Представника'],
    uploadPercentage: 0,
    cvalue: 0,
    refCount: 0,
    opDesc: [],
    isLoading: false,
    //
  }),

  watch:{
    $route (){
        setTimeout(this.push_check,500);
    }
  } ,
  created() {



    //this.intervalid1 = setInterval(this.push_check, 60000);






    console.log(API_LOCATION);


    // axios
    //   .post(
    //     API_LOCATION,
    //     {
          
    //       action: "version_check"
          
    //     },
    //     { headers: { "content-type": "application/x-www-form-urlencoded" } }
    //   )
    //   .then((resp) => {
    //           console.log(resp.data);  
    //           var version=190
    //           if(resp.data!==version)
    //           {
    //             location.reload();  
    //           }
        
    //   });


    console.log(this.$store);
    axios.interceptors.request.use(
      (config) => {
        this.setLoading(true);

        return config;
      },
      (error) => {
        this.setLoading(false);
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
          if (
            error.status === 401 &&
            error.config &&
            !error.config.__isRetryRequest
          ) {
            this.$store.dispatch("logout");
          }
          console.log(error);
          return error;
        });
      }
    );

    axios.interceptors.response.use(
      (response) => {
        this.setLoading(false);
        return response;
      },
      (error) => {
        this.setLoading(false);
        //eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => { 
          console.log(error);
          if (error.response.status === 401) {
            this.$store.dispatch("logout").then(() => {
              this.$router.push("/login");
            });
          }
        });
      }
    );
    axios.interceptors.response.use(undefined, function (err) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout");
        }
        console.log(err.status);
        throw err;
      });
    });

    this.$store.dispatch("page_title_set", "main");
    if (this.isLoggedIn) {
      this.$store.dispatch("profileset")
      .catch((err) => console.log(err));
    }
  },

  

    methods: {

    pushe(data){
      this.push_dialog=true;
      this.push_header=data.header;
      this.push_sender=this.sender_types[data.sender];
      this.push_text=data.text;
      console.log('push emit:'+data);
    },


    push_check(){
      if (this.isLoggedIn) {
      console.log('push_check');
      axios
      .post(
        API_LOCATION,
        {
          
          action: "push"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
        console.log(resp.data);
              if(resp.data!=false)         {
                this.pushe({header:resp.data.header,text:resp.data.message,sender:resp.data.type});              }
        
      });
      }
    },


      go_back(){
          //if(this.$route.name=='Phototask'||this.$route.name=='Testtask'){this.$router.push({ name: 'Main' })} else { this.$router.go(-1) this.$router.}
           this.$router.go(-1)
      },

    setLoading(isLoading) {
      if (isLoading) {
        this.refCount++;
        this.isLoading = true;
      } else if (this.refCount > 0) {
        this.refCount--;

        this.isLoading = this.refCount > 0;
      }
      this.cvalue = Math.round(100 / this.refCount);
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  
    
    
}

</script>
    

<style>
/*
#sapico{
  background-image: url('/img/sapico.png');
  width:48px;height:48px;
  position:absolute;top:5px;right:5px;
}*/
/* customizable snowflake styling */
.snowflake {
  /* color: #cccccc;
  font-size: 2em;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 5px #000; */
  background-image: url('/img/snow2.png');
  width: 32px;
  height:32px;
  background-size: contain;
}

.snowflake2 {
  /* color: #cccccc;
  font-size: 2em;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 5px #000; */
  background-image: url('/img/snow3.png');
  width: 32px;
  height:32px;
  background-size: contain;
  /*
  -webkit-animation-name:snowflakes-shake2;
  -webkit-animation-duration:3s;
  -webkit-animation-timing-function:ease-in-out;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-play-state:running;
  animation-name:snowflakes-shake2;
  animation-duration:3s;
  animation-timing-function:ease-in-out;
  animation-iteration-count:infinite;
  animation-play-state:running;*/

}

@-webkit-keyframes snowflakes-fall
{0%{top:-10%}100%{top:100%}}

@-webkit-keyframes snowflakes-shake
{0%,
100%{-webkit-transform:translateX(0);transform:translateX(0)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}}
@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}
@keyframes snowflakes-shake{0%,100%{transform:translateX(0)}50%{transform:translateX(80px)}}
@keyframes snowflakes-shake2{0%,100%{transform:translateX(0) translateY(0)}50%{transform:translateX(80px) translateY(80px)}}

.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;
-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}.snowflake:nth-of-type(10){left:25%;-webkit-animation-delay:2s,0s;animation-delay:2s,0s}.snowflake:nth-of-type(11){left:65%;-webkit-animation-delay:4s,2.5s;animation-delay:4s,2.5s}

.new-year{
  padding:0 !important;
  border-radius: 18px !important;
}

.card{
   position:fixed !important;top:90px !important;left:0 !important;right:0 !important; margin:auto !important;z-index:1000 !important;width:80% !important;
}
.card-ny{
  position:fixed !important;top:90px !important;left:0 !important;right:0 !important; margin:auto !important;z-index:1000 !important;width:80% !important;border-radius: 18px !important;
}

.cardstyle1{
  background-color:#fdaa47;color:white;text-align:center; padding:6px !important;
}


.cardstyle2{
  background-color:#ffffff;color:white;text-align:center; padding:6px !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-width: 3px;
  border-bottom-color: black;
  border-bottom-style: solid;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top-width: 3px;
  border-top-color: black;
  border-top-style: solid;
}

</style>
<style lang="css" scoped>
.v-dialog__content--active{
  backdrop-filter: blur(5px);
}
</style>